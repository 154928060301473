.headerhead{

  text-align: end;
  padding: 6px 0;

}


.headerhead p span{
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}
.cursor_hand{
  cursor: pointer;
}
.headerhead p{
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}
.headbaground{
  background-color:#01A3D8;
}
.header_main {
 
  
  background-color:#000000;

  padding: 25px 40px;
 
}
.mobilenavbar{
  display: none;
}
.nav{
  padding: 15px 0 15px 15px;
  
}

.drpdwn:hover .drpcontent{
  display: block;

 }
.navbar a {
  color:#ffffff;
  text-decoration: none;

  font-size: 15px;
  font-weight: 400;

}
.navmenus a{
  color:#ffffff;
  text-decoration: none;

  font-size: 15px;
  font-weight: 400;

}
.smallscreennavbar{
  display:none;
}
.navbar{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center ;
}
.navbar a:hover {
  border-bottom: 3px solid;
  border-color: #06a1d7;
  color:#ffffff;
}
.navmenus{
  /* display: flex !important; */
  justify-content: space-between !important;
}

.headlog a:hover {
  /* If you want to ensure there's no hover effect, reset any styles here */
  filter: none;
  transform: none;
}
.header_logo {
  width: 230px;
  cursor: pointer;
  
}
.no-hover {
border-bottom: 0px;

}


.navactive{
  border-bottom: 1px solid #01A3D8;
}
.loginbtn {
  padding: 14px 62px;
  /* font-size: 16px; */
  border-radius: 50px;
  background-color: #06a1d7;
  font-size: 15px;
  font-weight: 400;
  border: none;
  color: white;
 
}

.loginbtn:hover {
  background-color: #006f96 !important;
  border-color: #006f96 !important;
  color: white !important;
}
.userbtn {
  padding: 15px 80px 15px;

  border-radius: 20px;
  background-color: white;
  font-size: 17px;
  font-weight: 600;
  margin-right: 83px;
}
.drpdwn {
  border-radius: 0px !important;
position: absolute;
right: 0;
  min-width: 7rem !important;

  background: white;
 position: relative !important;

 background: #7a7a7a78 !important;
 color: #ffffff !important;
 text-align: right !important;


}
.country{
  font-weight: bold !important;
    background-color: #d0dbe4 !important;
    color: #191919 !important;
    margin-top: -8px;

}
.state{
  font-weight: bold !important;
  background-color: #d0dbe4 !important;
  color: #191919 !important;
  margin-top: -8px;
}
.drpdwn a{
  color: #ffffff !important;

}
.drpdwn a:hover{
  border: none;
  background-color: #01a3d8;
  color: #ffffff !important;
}
.stateDrpDwm a{
  color: #191919 !important;
}
.stateDrpDwm a:hover{
  border: none;
  background-color: #01a3d8;
  color: #ffffff !important;
}

.stateDrpDwm {
  top: 80px;
  left: 125px;
  border-radius: 0px !important;
  margin-top: 5px !important;
 
}

.stateDrpDwm a:hover {
  border: none;
}

.drpdwn a:hover {
  border: none;
}
.smallscreen.navbar{
  display: none;
}

.sidebar {
  position: fixed; /* Fix it in place */
  top: 0;
  right: -100%; /* Initially hidden */
  width: 30vw;
  height: 100vh; /* Full viewport height */
  background-color: black;
  color: white;
  transition: right 0.3s ease-in-out;
  /* padding: 20px; */
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* Allow scrolling inside the sidebar */
  z-index: 1000; /* Ensure it's above other elements */

}
.sidebar.open {
  right: 0;
  top: 36px;
  display: flex
  ;
      justify-content: right;
    
      text-align: right;
  

}
.mainsectionsidebar{
  width: 60%;

  margin-right: auto;
}

@media(min-width:768px) and (max-width:1024px) {
  .mobilenavbar{
    display: none;
  }
.header_main{
 width: 100%;
 padding: 26px 56px 13px 68px;
 display: block;
  }
  .smallscreennavbar{
    display: block;
  }
  .smallhead{
    display: flex;
    justify-content: space-between;
  }

  .smallscreen.navbar {
    display: block;
    width: 100%;
    background-color: black;
  
  }


.headerhead{
  display:none;
}

  .loginbtn
 {
    padding: 13px 59px;
   
    border-radius: 50px;
    background-color: #06a1d7;
    font-size: 15px;
    font-weight: 400;
    border: none;
    color: white;
  }

  .userbtn{
    padding: 13px 59px;

    border-radius: 50px;
    background-color: #06a1d7;
    font-size: 15px;
    font-weight: 400;
    border: none;
    color: white;
    margin-right: 0px;
  }
  .navbar a {
    font-size: 13px;
    color: white;
    display: block;
    padding: 11px 6px 10px 6px;
    text-align: center;
   
    margin: 0px;

  }

  

  .navbar {
    margin-top: 20px;
    display: flex  !important;
    justify-content: space-between !important;
  }
  

  
  .bigscreen{
    display:none;
  }

}


@media (min-width:300px) and (max-width:767px) {
  .mobilenavbar{
    display: block;
    justify-content: center !important;
  }
  .smallscreennavbar{
    display: none;
  }
  .bigscreen{
    display: none;
  }
  .header_logo{
    /* width: 100%; */
    
  
  }

  .headerhead{
    display:none;
  }

  .loginmobile{
    font-size: 18px !important;
  }
.navbartoggle{
  text-align: right;
}
.navbartogglericon {
   background-color: white !important;
   padding: 10px  !important;
   border-radius: 4px !important;
}

  .navbar{
    margin-top: 10px;
    display: block;
    align-items: center;
  }

  .header_main{
    padding: 30px;
  }
  .navbar a{
    border-bottom: 0px;
    text-align: center;
    font-size: 10px;
    padding: 10px;
    margin: 0px;
  }
  .loginbtn{
    width: 100%;
    margin-top: 15px;
    /* width: 100%;
    margin-top: 28px; */
  }
  .userbtn{
    width: 100%;
    margin-top: 28px;
  }
  .drpdwn{
    width: 50% !important;
    left: auto !important;
  }
  .navbar a:hover {
    border-bottom: none;

  }
 
}

/* Styles for screens wider than 768px */


