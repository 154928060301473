

/* @import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;

}
/* .App {
  text-align: center;
} */
html{
  scroll-behavior: smooth;
}

.content {
  margin-top: 80px; /* Adjust based on your header height */
  margin-bottom: 60px; /* Adjust based on your footer height */
  /* padding: 20px; */
}
.greenbtn {
  background-color: #a0ab8d;
  padding: 6px 40px 10px;
  border-radius: 10px;
  color: black;
  font-weight: 600;
  border: none;
}
.greenbtn:hover {
  background-color: #198754;
}
.authBlubtn {
  --bs-btn-bg: #06a1d7 !important;
  --bs-btn-border-color: #06a1d7 !important;
}
.authBlubtn:hover {
  background-color: #006f96 !important;
  border-color: #006f96 !important;
  color: white !important;
}
.bluebtn {
  background-color: #06a1d7;
  padding: 6px 40px 10px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.bluebtn:hover {
  background-color: #006f96 !important; /* Darker color on hover */
  color: white !important;
  border: 1px solid #006f96 !important;
}
.yellowbtn {
  background-color: #d4a249;
  padding: 6px 40px 10px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.greenbg {
  background-image: url("/public/dashboard/greenbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  height: 50vh;
  justify-content: center;
  display: flex;
  align-items: center;
}
.greenText {
  font-size: 38px;
  font-weight: 500;
  color: #feebc3;
}
.image-container {
  background-blend-mode: multiply;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 0, 0, 0.5);  */
  background: rgba(0, 0, 0, 0.4);
  /* cursor: pointer; */
}
.overlayIcon {
  cursor: pointer;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
body.sidebar-open {
  overflow: hidden;

}
@media (max-width: 768px) {
  * {
    font-size: 12px;
    line-height: 1.5;
  }
  .greenText {
    font-size: 22px;
  }
}
